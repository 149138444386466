import React from "react";
import Icon from "./icon";
import { format } from "date-fns";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import { cn } from "../lib/helpers";

import * as styles from "../styles/components/media-details.module.css";

function MediaDetails(props) {

  const {
    id,
    title,
    logo,
    quote,
    externalLink,
    publishedOn,
    category,
    mediaFile,
    cover,
    bigCover,
  } = props;

  return (
    <div key={id} className={cn(styles.root, bigCover && styles.big)} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
      <div className={styles.containerContent}>
        {(publishedOn || category) && (
          <div className={styles.meta}>
            {publishedOn && (
              <span>{format(new Date(publishedOn).setDate(new Date(publishedOn).getDate() + 1), "dd.MM.yyyy")}</span>
            )}
            {category && (
              <span>{category.title}</span>
            )}
          </div>
        )}
        {((mediaFile && mediaFile.asset) || (externalLink && externalLink.linkUrl) || (cover && cover.asset)) && (
          <div className={styles.media}>
            {cover && cover.asset && (
              <div className={styles.cover}>
                <img
                  src={imageUrlFor(buildImageObj(cover))
                    .auto("format")
                    .url()}
                  alt={cover.alt}
                />
              </div>
            )}
            {((mediaFile && mediaFile.asset) || (externalLink && externalLink.linkUrl)) && (
              <>
                {(externalLink && externalLink.linkUrl) ? (
                  <a className={styles.action} href={externalLink.linkUrl} target="_blank" rel="noreferrer"><Icon symbol="external" /><span className="hidden">Media</span></a>
                ) : (
                  <a className={styles.action} href={mediaFile.asset.url} target="_blank" rel="noreferrer"><Icon symbol="external" /><span className="hidden">Media</span></a>
                )}
              </>
            )}
          </div>
        )}
        {title && (
          <div className={styles.content}>
            {logo && logo.asset && (
              <img
                src={imageUrlFor(buildImageObj(logo))
                  .auto("format")
                  .url()}
                alt={logo.alt}
              />
            )}
            <h4 className={styles.title}>{title}</h4>
            {quote && (
              <p>"{quote}"</p>
            )}
            {((mediaFile && mediaFile.asset) || (externalLink && externalLink.linkUrl)) && (
              <>
                {(externalLink && externalLink.linkUrl) ? (
                  <a className={styles.more} href={externalLink.linkUrl} target="_blank" rel="noreferrer">{externalLink.text}<Icon symbol="external" /></a>
                ) : (
                  <a className={styles.more} href={mediaFile.asset.url} target="_blank" rel="noreferrer">{externalLink.text}<Icon symbol="external" /></a>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default MediaDetails;
