import React from "react";
import { graphql, StaticQuery } from "gatsby";
import MediaDetails from "./media-details";

import * as styles from "../styles/components/media-list.module.css";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query MediaQuery {
    media: allSanityMedia(
      sort: { fields: [publishedOn], order: DESC }
      filter: { title: { ne: null } }
    ) {
      edges {
        node {
          id
          title
          logo {
            ...SanityImage
            alt
          }
          quote
          externalLink {
            text
            linkUrl
          }
          publishedOn
          category {
            title
          }
          mediaFile {
            asset {
              url
            }
          }
          cover {
            ...SanityImage
            alt
          }
          bigCover
        }
      }
    }
  }
`;

const MediaList = (props) => {

  return (
    <div className={styles.root}>

      <StaticQuery
        query={query}
        render={data => (

        <>

        {data.media.edges.map(({ node: media }) => {

          return (
            <MediaDetails {...media} />
          );

        })}

        </>

      )} />

    </div>
  );
}

export default MediaList;
