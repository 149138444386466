import React from "react";
import Container from "./container";
import PortableText from "./portableText";

import * as styles from "../styles/components/page-header.module.css";

const PageHeader = (props) => {

  const {
    _rawHeading,
    description
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.containerInner}>
          {_rawHeading && (
            <h1 className={styles.heading}>
              <PortableText blocks={_rawHeading} />
            </h1>
          )}
          {description && (
            <div className={styles.containerContent}>
              <p>
                {description}
              </p>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}

export default PageHeader;
