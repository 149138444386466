import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import Container from "../components/container";

import PageHeader from "../components/page-header";
import MediaList from "../components/media-list";

import * as styles from "../styles/components/media-archive.module.css";

export const query = graphql`
  query MediaPageQuery {
    media: sanityMediaArchive {
      pageHeader{
        _rawHeading
        description
      }
    }
  }
`;

const MediaPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const media = (data || {}).media;

  return (
    <Layout>
      <Seo
        title="Media Archive"
        description={media.pageHeader.description}
      />

      {media.pageHeader && (
        <PageHeader {...media.pageHeader} />
      )}

      <div className={styles.root}>
        <Container><MediaList /></Container>
      </div>
      
    </Layout>
  );
};

export default MediaPage;
